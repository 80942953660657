
import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  colors: {
    brand: {
      50: '#edfdf4',
      55:'#7bd148',
      100: '#e2fbed',
      200: '#c2ebd4',
      300: '#9fddb9',
      400: '#7ccf9e',
      500: '#58c184',
      600: '#3ea76a',
      700: '#2e8251',
      800: '#1f5d3a',
      900: '#0f3921',
      1000: '#001506',
    },
  },
});