import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"

/* import { Formik, Form } from 'formik'; */

/* import { projectSchema } from 'utils/validationSchemas';
import { getInitialValues } from './functions'; */
/* import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';


import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'; */

// REVIEW: extend db with common API calls
import { Wizard } from 'components/InvoiceForms/Wizard';


function InvoiceDialog(props) {
    const { open, onClose } = props;

    //material ui custom styles
    const useStyles = makeStyles(theme => ({
        appBar: {
          position: "relative",
          paddingRight: 10,
          paddingLeft: 10
        },
        svg: {
          verticalAlign: "middle"
        },
        layout: {
          width: "auto",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: "auto",
            marginRight: "auto"
          }
        },
        paper: {
          margin: theme.spacing(3),
          padding: theme.spacing(2),
          [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
            padding: theme.spacing(3)
          }
        }
      }))

      const classes = useStyles()

    /* const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = React.useState(false); */

    /* const theme = useTheme();
    const customSnackbar = useContext(CustomSnackbarContext); */

    // REVIEW: we could make a Menu context to reuse across the entire app
    /* const handleMore = event => {
        setAnchorEl(event.currentTarget);
    }; */

  

 /*    const handleArchive = () => {
        db.collection('invoices')
            .doc(invoice.id)
            .update({
                archived: true
            })
            .catch(error => {
                customSnackbar.error('An error has happened. Please try again.');
                console.error(error);
            });

        customSnackbar.success('Project archived.');
        setAnchorEl(null);
        onClose();
    }; */

/*     const handleReactivate = () => {
        db.collection('invoices')
            .doc(invoice.id)
            .update({
                archived: false
            })
            .catch(error => {
                customSnackbar.error('An error has happened. Please try again.');
                console.error(error);
            });

        customSnackbar.success('Project reactivated.');
        setAnchorEl(null);
        onClose();
    }; */

 /*    const handleDelete = () => {
        db.collection('invoices')
            .doc(invoice.id)
            .delete()
            .catch(error => {
                customSnackbar.error('An error has happened. Please try again.');
                console.error(error);
            });

        customSnackbar.success('Project deleted.');
        setDeleteConfirmationDialogOpen(false);
        setAnchorEl(null);
        onClose();
    }; */

   /*  const handleSubmit = (values, { resetForm }) => {
        if (updateMode) {
            db.collection('invoices')
                .doc(invoice.id)
                .update({
                    FirstName: values.FirstName,
                    LastName: values.LastName,
                    email:values.email,
                    PhoneNumber:values.PhoneNumber,
                    ProfessionalFees:values.ProfessionalFees,
                    instructions: values.instructions,
                    disbursments: values.disbursments
                })
                .catch(error => {
                    customSnackbar.error('An error has happened. Please try again.');
                    console.error(error);
                });
        } else {
            const currentUserRef = db.collection('users').doc(firebase.auth().currentUser.uid);

            db.collection('invoices')
                .add({
                    created_at: new Date(),
                    archived: false,
                    FirstName: values.FirstName,
                    LastName: values.LastName,
                    email:values.email,
                    PhoneNumber:values.PhoneNumber,
                    ProfessionalFees:values.ProfessionalFees,
                    instructions: values.instructions,
                    disbursments: values.disbursments,
                    owner_uid: currentUserRef
                })
                .catch(error => {
                    customSnackbar.error('An error has happened. Please try again.');
                    console.error(error);
                });

            customSnackbar.success('Project created.');
        }

        resetForm();
        onClose();
    }; */

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
                aria-labelledby="project-dialog"
                onClose={onClose}
           >
               <Paper className={classes.paper}>
                <Wizard onClose={onClose} />
               </Paper>
            </Dialog>

            {/* <ConfirmationDialog
                danger
                open={deleteConfirmationDialogOpen}
                dialogTitle="Delete project?"
                dialogContentText="Time entries you logged for this project will not be deleted."
                action="Delete"
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                onSubmit={handleDelete}
            /> */}
        </>
    );
}

export default InvoiceDialog;
