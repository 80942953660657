import React from "react";

import { FormizStep } from "@formiz/core";
import { isMaxNumber } from "@formiz/validations";


import { FieldInput } from "./Fields/FieldInput";
import { FieldSelect } from "./Fields/FieldSelect";
import { Heading } from "@chakra-ui/react";

const StudentFeesStep = () => {
  return (
    <>
      <FormizStep name="step2">
        <Heading mt={2} mb={8} size="md">
          Student Fees
        </Heading>
        <FieldInput
          name="SchoolFees"
          label="School fees"
          placeholder="School fees"
          required="Required"
          validations={[
            {
              rule: isMaxNumber(400000),
              message: "Should be a number under 400000",
            },
          ]}
        />
        <FieldInput name="Bus" label="Bus" placeholder="Bus" />
        <FieldInput name="Uniform" label="Uniform Fee" placeholder="Uniform Fee" />
        <FieldInput name="MockFee" label="Mock fee" placeholder="Mock fee" />
        <FieldInput
          name="AdmissionFee"
          label="Admission Fee"
          placeholder="Admission Fee"
        />
        <FieldSelect
          name="Ream"
          label="Ream of Papers"
          placeholder="Ream of Papers"
          required="Required"
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
        <FieldInput
          name="RecieptNumber"
          label="Reciept Number"
          placeholder="Reciept Number"
        />
      </FormizStep>
    </>
  );
};

export default StudentFeesStep;
