import React from 'react';
import { Heading } from "@chakra-ui/react"
//Vendor
/* import Header from '../../header/Header'; */
/* import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase'; */
// Custom
import {
  UserTable,
  UserListHead,
  TableHeading
} from '../../components/styledComponents/invoices/invoiceTables';
/* '../../styledComponents/invoices/invoiceTables'; */
import UserListItem from './AdminUserListItem';
import InvoiceListLoader from '../../components/loaders/InvoiceListLoader';

/* import NothingHere from '../../loaders/welcome/NothingHere'; */


// Component
function UsersTable({UsersLoading, Users}) {
 

  let tableListItems;

/*   if ( InvoicesLoading ) {
    return <FullPageLoader />;
} */
if (UsersLoading) {
  return <InvoiceListLoader />
} else {
  /* const sortedInvoices = Invoices.sort((a, b) => b.created_at - a.created_at) */
  tableListItems = Users.map((user) => (
    <UserListItem user={user} key={user.id} />
  ));
}


  return (
    <div>
      {/* <Header title={'Invoices'} /> */}
      <div style={{ padding: '1rem' }}>
        <UserTable style={{ margin: '0 auto' }}>
          <TableHeading>
          <Heading as="h5" size="md">
          Attorneys
          </Heading>
            {/* <h2 style={{ivHStyle}}>Attorneys</h2> */}
          </TableHeading>
          <UserListHead>
            <p className="listHead name">Attorney name</p>
            <p className="listHead option"></p>
          </UserListHead>
          {tableListItems}
        </UserTable>
      </div>
    </div>
  );
}

export default UsersTable;