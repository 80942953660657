import React, { /* useState, useContext */} from 'react';
import { Grid, Hidden } from '@material-ui/core';

/* import _ from 'lodash'; */

import Box from '@material-ui/core/Box';
/* import Grid from '@material-ui/core/Grid'; */
/* import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch'; */

/* import NewInovice from './NewInvoice';

import InvoiceDialog from './InvoiceDialog'; 

import { DbAdminInvoicesContext } from 'utils/firebase'; */

/* import InvoicesTable from './InvoicesTable'; */
import TotalInvoices from './widgets/TotalInvoices';
import TotalUsers from './widgets/TotalUsers';
import RecentInvoicesTable from './RecentInvoicesTable';
import PieGraph from './widgets/PieGraph';
/* import { orderAndFilterAndPopulateProjects } from './functions'; */

// TODO: in future, archived projects should go into a separate List and have an un-archive option as action
function AdminPage(props) {
   /*  const [InvoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [InvoiceSelectedId, setInvoiceSelectedId] = useState(null);
    const [showArchivedInvoices, setShowArchivedInvoices] = useState(false);
 */

  
    

/*     const handleCreateInvoice = () => {
        setInvoiceDialogOpen(true);
        setInvoiceSelectedId(null);
    }; */


    /* const InvoiceSelected = _.find(invoices, i => i.id === InvoiceSelectedId); */
    /* const orderedAndFilteredInvoices = useMemo(
        () => orderAndFilterAndPopulateInvoices(invoices, showArchivedInvoices),
        [inovices, showArchivedInvoices]
    ); */

    return (
        <Box>
        <Box p={2} style={{display:"flex", alignContent:"center"}}>
            <Box>
                <TotalInvoices></TotalInvoices>
            </Box>
            <Box>
                <TotalUsers></TotalUsers>
            </Box>      
        </Box>
        <Box style={{display:"flex", alignItems:"baseline"}}>
            <RecentInvoicesTable></RecentInvoicesTable>
            <Hidden mdDown>
            <Grid item md={3} lg={3}>
                <PieGraph></PieGraph>
            </Grid>
            </Hidden>
        </Box>
        
        </Box>
    );
}

export default AdminPage;
