import React, { useState, useContext} from 'react';

import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';


import NewInovice from './NewInvoice';

import InvoiceDialog from './InvoiceDialog'; 

import { DbInvoicesContext } from 'utils/firebase';

import InvoicesTable from './InvoicesTable';
/* import { orderAndFilterAndPopulateProjects } from './functions'; */

// TODO: in future, archived projects should go into a separate List and have an un-archive option as action
function Invoices(props) {
    const [InvoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [InvoiceSelectedId, setInvoiceSelectedId] = useState(null);
    


    const { invoices } = useContext(DbInvoicesContext);
    

    const handleCreateInvoice = () => {
        setInvoiceDialogOpen(true);
        setInvoiceSelectedId(null);
    };


    const InvoiceSelected = _.find(invoices, i => i.id === InvoiceSelectedId);
    /* const orderedAndFilteredInvoices = useMemo(
        () => orderAndFilterAndPopulateInvoices(invoices, showArchivedInvoices),
        [inovices, showArchivedInvoices]
    ); */

    return (
        <Box p={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}> 
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3} zeroMinWidth>
                    <NewInovice onActionClick={handleCreateInvoice} />
                </Grid>
               {/*  {orderedAndFilteredProjects.map(project => (
                    <Grid key={project.id} item xs={12} sm={6} lg={4} xl={3} zeroMinWidth>
                        <Project {...project} onActionClick={() => handleEditProject(project.id)} />
                    </Grid>
                ))} */}
                
            </Grid>
            <InvoicesTable></InvoicesTable>
             <InvoiceDialog
                open={InvoiceDialogOpen}
                invoice={InvoiceSelected}
                onClose={() => setInvoiceDialogOpen(false)}
            /> 
        </Box>
    );
}

export default Invoices;
