import React, { useState, useContext } from 'react';
import { Formiz, useForm } from '@formiz/core';
import { Button, Grid, Box } from '@chakra-ui/react';
/* import { InstructionsStep } from './InstructionsStep';
import { DisbursementsStep } from './DisbursementsStep';
import ClientInformationStep from './ClientInformationStep'; */
import DotsStepper from './DotsStepper';
import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';

//db
import firebase, { db } from 'utils/firebase';
import { DbUserContext } from 'utils/firebase';
/* import goodid from 'goodid.js' */
import StudentInformationStep from './StudentInformationStep';
import StudentFeesStep from './StudentFeesStep';




const fakeDelay = (delay = 500) => new Promise((r) => setTimeout(r, delay));


export const Wizard = ({onClose}) => {
  const form = useForm({ subscribe: 'form' });
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'loading' || form.isValidating;
  const customSnackbar = useContext(CustomSnackbarContext);

  const { user } = useContext(DbUserContext);

  /**
   * Hook into the submitStep method to handle some actions before changing step
   * If you don't need this you can just call the onSubmit={form.submitStep} on your <form>
   */
  const handleSubmitStep = async (event) => {
    event.preventDefault();
    if (
      !form.currentStep
      || !form.currentStep?.isValid
      || !form.currentStep?.name
    ) {
      form.submitStep();
      return;
    }

    setStatus('loading');
    console.log(`Submitting ${form.currentStep?.name}...`); // eslint-disable-line no-console
    await fakeDelay();

    setStatus('success');
    form.submitStep();
  };

  /**
   * Handle the complete form submit
   */
  const handleSubmit = async (values) => {
    setStatus('loading');


    /* debug */

   
    console.log('Submitting form...', values);
    
    // eslint-disable-line no-console
    
    const currentUserRef = db.collection('users').doc(firebase.auth().currentUser.uid);

     /* total from disbursements */


    /* generate invoice number */
  /*   goodid.config({
      length: 8,
      prefix: 'GHS/',
      alphabet: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
      startTime: new Date().getTime()
  })

    //entry number
    const entryNumber = ( ) =>   goodid()
    const entNumber = entryNumber(); */

    /* console.log("invoiceNUmber1",invNum);
    console.log("invoiceNumber2",invNum); */


/*     const test = {
      created_at: new Date(),
      archived: false,
      FirstName: values.FirstName,
      LastName: values.LastName,
      email:values.email,
      PhoneNumber:values.PhoneNumber,
      ProfessionalFees:values.ProfessionalFees,
      instructions: values.instructions,
      disbursments: values.disbursments,
      currency:values.currency,
      totalAmount: totalAmount,
      invoiceNumber:'',
      owner_uid: currentUserRef
    }

    console.log("test data", test) */


    /* total from disbursements */

    //Captalize first letter helper
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }


    db.collection('students')
        .add({
            created_at: new Date(),
            givenName: capitalizeFirstLetter(values.givenName),
            surName: capitalizeFirstLetter(values.surName),
            Class:values.Class,
            Stream:values.Stream,
            Term:values.Term,
            ScholarType:values.ScholarType,
            SchoolFees:parseFloat(values.SchoolFees),
            UniformFees: parseFloat(values.Uniform),
            AdmissionFee: parseFloat(values.AdmissionFee),
            BusFee:parseFloat(values.Bus),
            MockFee:parseFloat(values.MockFee),
            Ream: values.Ream,
            owner_uid: currentUserRef,
        })
        .catch(error => {
            customSnackbar.error('An error has happened. Please try again.');
            console.error(error);
        });
    
    onClose()
    customSnackbar.success('Invoice created.');


    setStatus('success');

  /*   toastValues(values);
    form.invalidateFields({
      name: 'You can display an error after an API call',
    }); */
    const stepWithError = form.getFieldStepName('name');
    if (stepWithError) {
      form.goToStep(stepWithError);
    }
  };

  return (
    <Formiz connect={form} onValidSubmit={handleSubmit}>
        <form noValidate onSubmit={handleSubmitStep}>

         <StudentInformationStep></StudentInformationStep>
         <StudentFeesStep></StudentFeesStep>
          {!!form.steps?.length && (
            <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
              {!form.isFirstStep && (
                <Button gridColumn="1" onClick={form.prevStep}>
                  Previous
                </Button>
              )}
              <Box
                gridColumn="2"
                textAlign="center"
                fontSize="sm"
                color="gray.500"
              >
                {/* Step {(form.currentStep?.index ?? 0) + 1} / {form.steps.length} */}
                <DotsStepper></DotsStepper>
              </Box>
              <Button
                type="submit"
                gridColumn="3"
                colorScheme="brand"
                isLoading={isLoading}
                isDisabled={
                  (form.isLastStep ? !form.isValid : !form.isStepValid)
                  && form.isStepSubmitted
                }
              >
                {form.isLastStep ? 'Submit' : 'Next'}
              </Button>
            </Grid>
          )}
        </form>
    </Formiz>
  );
};