import { useState, useEffect } from 'react';

import { db } from 'utils/firebase';

const useDbUsers = () => {
    const [usersLoading, setUsersLoading] = useState(true);
    const [usersError, setUsersError] = useState(false);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        const unsubscribe = db.collection('users')
            .onSnapshot(
                querySnapshot => {
                    let attorneys = [];
                    querySnapshot.forEach(doc => {
						let attorney = doc.data();
						attorney.id = doc.id;
                        attorneys.push(attorney);
                    });
                    setUsers(attorneys);
                    setUsersLoading(false);
					// console.info(`%cuseDbusers: ${querySnapshot.docChanges().length} document(s) read`, 'color: blue');
                },
                err => {
                    setUsersError(err);
                    // console.error(err);
                }
            );
        return () => {
            unsubscribe();
            // console.info('%cuseDbUsers: unsubscribed', 'color: blue');
        };
    }, []);

    return { usersLoading, usersError, users };
};

export default useDbUsers;
