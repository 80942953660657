import React from "react";

import { FormizStep } from "@formiz/core";
import { isMinLength } from "@formiz/validations";

import { FieldInput } from "./Fields/FieldInput";
import { FieldSelect } from "./Fields/FieldSelect";
import { Heading } from "@chakra-ui/react";

const StudentInformationStep = () => {
  return (
    <>
      <FormizStep name="step1">
        <Heading mt={2} mb={8} size="md">
          Student Information
        </Heading>
        <FieldInput
          name="surName"
          label="Surname"
          placeholder="Surname"
          required="Required"
          validations={[
            {
              rule: isMinLength(3),
              message: 'This should at least have a length of 3',
            },
          ]}
        />
        <FieldInput
          name="givenName"
          label="Given Name"
          placeholder="Given Name"
          required="Required"
          validations={[
            {
              rule: isMinLength(3),
              message: 'This should at least have a length of 3',
            },
          ]}
        />
        <FieldSelect
          name="Class"
          label="Class"
          placeholder="Select Class..."
          required="Required"
          keepValue
          options={[
            { value: "S1", label: "S1" },
            { value: "S2", label: "S2" },
            { value: "S3", label: "S3" },
            { value: "S4", label: "S4" },
          ]}
        />
        <FieldSelect
          name="Stream"
          label="Stream"
          placeholder="Select Stream..."
          required="Required"
          keepValue
          options={[
            { value: "A", label: "Stream A" },
            { value: "B", label: "Stream B" },
            { value: "C", label: "Stream C" },
            { value: "D", label: "Stream D" },
          ]}
        />
        <FieldSelect
          name="Term"
          label="Term"
          placeholder="Select School Term..."
          required="Required"
          keepValue
          options={[
            { value: "Term 1", label: "Term 1" },
            { value: "Term 2", label: "Term 2" },
            { value: "Term 3", label: "Term 3" },
          ]}
        />
        <FieldSelect
          name="ScholarType"
          label="Scholar Type"
          placeholder="Select Scholar Type..."
          required="Required"
          keepValue
          options={[
            { value: "Day", label: "Day" },
            { value: "Boarding", label: "Boarding" },
          ]}
        />
      </FormizStep>
    </>
  );
};

export default StudentInformationStep;
