import React, { useContext} from 'react';
import { Heading } from "@chakra-ui/react"
import FileSaver from 'file-saver';
import { parse } from 'json2csv';
import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';
import Button from '@material-ui/core/Button';
//Vendor
/* import Header from '../../header/Header'; */
/* import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase'; */
import moment from 'moment';
// Custom
import {
  InvoiceTable,
  InvoiceListHead,
  TableHeading
} from '../../components/styledComponents/invoices/invoiceTables';
/* '../../styledComponents/invoices/invoiceTables'; */
import InvoiceListItem from './AdminInvoiceListItem';
import InvoiceListLoader from '../../components/loaders/InvoiceListLoader';

/* import NothingHere from '../../loaders/welcome/NothingHere'; */


// Component
function InvoicesTable({InvoicesLoading, Invoices}) {

  const customSnackbar = useContext(CustomSnackbarContext);

  let tableListItems;

/*   if ( InvoicesLoading ) {
    return <FullPageLoader />;
} */
if (InvoicesLoading) {
  return <InvoiceListLoader />
} else {
  const sortedInvoices = Invoices.sort((a, b) => b.created_at - a.created_at)
  tableListItems = sortedInvoices.map((invoice) => (
    <InvoiceListItem invoice={invoice} InvoicesLoading={InvoicesLoading} key={invoice.id} />
  ));
}


const handleDownloadCSV = adminInvoices => {
  try {

    /*   adminInvoices.forEach(invoice => {
        console.log(invoice.invoiceNumber, moment(invoice.created_at.toDate()).format('DD-MM-YYYY'))
      }); */

      const data = adminInvoices.map(invoice => ({
          invoice_number: invoice.invoiceNumber,
          created: moment(invoice.created_at.toDate()).format('DD-MM-YYYY'),
          attorney: invoice.author.name,
          client: `${invoice.FirstName} ${invoice.LastName}`,
          totalamount: invoice.totalAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }),
          currency: invoice.currency,
          paid: invoice.paidStatus
      }));
      const dataSorted = data.sort((a, b) => b.created - a.created)
      const csv = parse(dataSorted, {
          fields: ['invoice_number', 'created','attorney', 'client', 'totalamount', 'currency', 'paid']
      });


      const csvBlob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(csvBlob, `bytinvoices.csv`);
  } catch (err) {
      customSnackbar.error('Error occured while saving CSV. Try again later.');
  }
};

  return (
    <div>
      {/* <Header title={'Invoices'} /> */}
      <div style={{ padding: '1rem' }}>
        <InvoiceTable style={{ margin: '0 auto' }}>
        <Heading as="h5" size="md" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableHeading>
            <h2>Invoice History</h2>
          </TableHeading>

          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDownloadCSV(Invoices)}
                        >
                            Download CSV
                        </Button>
         
          </Heading>
          <InvoiceListHead>
            <p className="listHead number">No.</p>
            <p className="listHead date">Date</p>
            <p className="listHead name">Author</p>
            <p className="listHead client">Client</p>
            <p className="listHead amount">Amount</p>
            <p className="listHead status">Status</p>
            <p className="listHead option"></p>
          </InvoiceListHead>
          {tableListItems}
        </InvoiceTable>
      </div>
    </div>
  );
}

export default InvoicesTable;