import React, { useContext } from 'react';
import { Heading } from "@chakra-ui/react"
//Vendor
/* import Header from '../../header/Header'; */
/* import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase'; */
// Custom
import {
  InvoiceTable,
  InvoiceListHead,
  TableHeading
} from '../../components/styledComponents/invoices/invoiceTables';
/* '../../styledComponents/invoices/invoiceTables'; */
import InvoiceListItem from './InvoiceListItem';
import InvoiceListLoader from '../../components/loaders/InvoiceListLoader';

/* import NothingHere from '../../loaders/welcome/NothingHere'; */

import {
  /* useDbInvoices, */
  DbInvoicesContext,
  DbStudentsContext
} from 'utils/firebase';



// Component
function InvoicesTable() {
  const {InvoicesLoading, Invoices} = useContext(DbInvoicesContext);
  const {StudentsLoading, StudentsError, Students} = useContext(DbStudentsContext);
  

  let tableListItems;

/*   if ( InvoicesLoading ) {
    return <FullPageLoader />;
} */
if (StudentsLoading) {
  return <InvoiceListLoader />
} else {
  const sortedStudents = Students.sort((a, b) => b.created_at - a.created_at)
  tableListItems = sortedStudents.map((student) => (
    <InvoiceListItem student={student} StudentsLoading={StudentsLoading} key={student.id}/>
  ));
}

  return (
    <div>
      {/* <Header title={'Invoices'} /> */}
      <div style={{ padding: '1rem' }}>
        <InvoiceTable style={{ margin: '0 auto' }}>
        <Heading as="h5" size="md">
          <TableHeading>
            <h2>Student School Fees History</h2>
          </TableHeading>
          </Heading>
          <InvoiceListHead>
            <p className="listHead name">Name</p>
            <p className="listHead class">Class</p>
            <p className="listHead amount">Fees Amount</p>
            <p className="listHead date">Date</p>
            <p className="listHead option"></p>
          </InvoiceListHead>
          {tableListItems}
        </InvoiceTable>
      </div>
    </div>
  );
}

export default InvoicesTable;