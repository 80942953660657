import { useState, useEffect } from 'react';
/* import {parse, stringify} from 'flatted'; */

// import moment from 'moment';

import firebase, { db } from 'utils/firebase';

const useDbInvoices = () => {
    const [InvoicesLoading, setInvoicesLoading] = useState(true);
    const [InvoicesError, setInvoicesError] = useState(false);
    const [Invoices, setInvoices] = useState(null);

    
    useEffect(() => {

        const currentUserRef = db.collection('users').doc(firebase.auth().currentUser.uid);
        // const date30DaysAgo = parseInt(moment().subtract(30, 'days').format('YYYYMMDD'));

        const unsubscribe = db
            .collection('invoices')
            .where('owner_uid', '==', currentUserRef)
            // .where('date', '>=', date30DaysAgo)
            .onSnapshot(
                querySnapshot => {
                    let invoices = [];
                    querySnapshot.forEach(doc => {
						let invoice = doc.data();
						invoice.id = doc.id;
                        invoices.push(invoice);
                    });
                    setInvoices(invoices);
                    setInvoicesLoading(false);
					// console.info(`%cuseDbInvoices: ${querySnapshot.docChanges().length} document(s) read`, 'color: blue');
                },
                err => {
                    setInvoicesError(err);
                    // console.error(err);
                }
            );
        return () => {
            unsubscribe();
            // console.info('%cuseDbInvoices: unsubscribed', 'color: blue');
        };
    }, []);

    return { InvoicesLoading, InvoicesError, Invoices };
};

export default useDbInvoices;
