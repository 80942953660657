
import React, { useState, useContext } from 'react';
//Vendor
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { db } from 'utils/firebase';

// Custom
import { InvoiceListItem as InvoiceList } from '../../components/styledComponents/invoices/invoiceTables';
import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';


// Component
function InvoiceListItem(props) {
  const customSnackbar = useContext(CustomSnackbarContext);
  const { invoice, InvoicesLoading } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  /* const dispatch = useDispatch(); */
  const open = Boolean(anchorEl);

  // console.log("invoicelistiten", invoice.currency);
  let currencySign = "UGX"

  if (!InvoicesLoading) {
      switch (invoice.currency) {
          case 'USD':
              currencySign ="$"
              break;
          case 'UGX':
              currencySign ="USh"
              break;
          case 'RWF':
              currencySign ="FRw"
              break;
          default:
              currencySign ="UGX"
              break;
      }
  }

  const handleOptionOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleDeleteInvoice = (e) => {
    e.stopPropagation();
    e.preventDefault();
  
      db.collection('invoices')
          .doc(invoice.id)
          .delete()
          .catch(error => {
              customSnackbar.error('An error has happened. Please try again.');
              console.error(error);
          });

      customSnackbar.success('Invoice deleted.');
      
    setAnchorEl(null);
  };

  const togglePaymentStatus = (e) => {
    e.stopPropagation();
    e.preventDefault();

    db.collection('invoices')
    .doc(invoice.id)
    .update({
        paidStatus: true
    })
    .catch(error => {
        customSnackbar.error('An error has happened. Please try again.');
        console.error(error);
    });

    customSnackbar.success('Status changed.');
    setAnchorEl(null);

  };

  
  return (
    <Link to={`/admin/invoice/${invoice.id}`}>
      <InvoiceList>
        <p className="number">{invoice.invoiceNumber}</p>
        <p className="date">
          {moment(invoice.created_at.toDate()).format('DD-MM-YYYY')}
        </p>
        <p className="client">{`${invoice.FirstName} ${invoice.LastName}`}</p>
        <p className="name author">{`${invoice.author.name}`}</p>

        <p className="amount">
        {/*   {invoice.taxType === 'inc' &&
            invoice.taxEnable === 'true' &&
            currencySign +
              invoice.totalAmount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
          {invoice.taxType === 'exc' &&
            invoice.taxEnable === 'true' &&
            currencySign +
              invoice.totalWithExclusiveTax.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
          {invoice.taxEnable === 'false' && */}
            {currencySign +
              invoice.totalAmount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
        </p>
        <p className="status">
          {invoice.paidStatus ? (
            <span style={{ color: '#219735' }}>Fulfilled</span>
          ) : (
            <span style={{ color: '#FD5665' }}>Pending</span>
          )}
        </p>
        <p className="option">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleOptionOpen}
            tabIndex={1}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            keepMounted
            open={open}
            onClose={handleOptionClose}
          >
            <MenuItem onClick={handleDeleteInvoice}>
              <p>Delete Invoice</p>
            </MenuItem>
            <MenuItem onClick={togglePaymentStatus}>
              {invoice.paidStatus ? (
                <p>Mark as Pending</p>
              ) : (
                <p>Mark as Paid</p>
              )}
            </MenuItem>
          </Menu>
        </p>
      </InvoiceList>
    </Link>
  );
}

export default InvoiceListItem;