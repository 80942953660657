import { useState, useEffect } from 'react';
/* import {parse, stringify} from 'flatted'; */

// import moment from 'moment';

import { db } from 'utils/firebase';

const useDbAdminInvoices = () => {
    const [AdminInvoicesLoading, setAdminInvoicesLoading] = useState(true);
    const [AdminInvoicesError, setAdminInvoicesError] = useState(false);
    const [AdminInvoices, setAdminInvoices] = useState(null);
    
    useEffect(() => {
/*         const currentUserRef = db.collection('users').doc(firebase.auth().currentUser.uid); */
        // const date30DaysAgo = parseInt(moment().subtract(30, 'days').format('YYYYMMDD'));
		
        const unsubscribe = db
            .collection('invoices')
            /* .where('owner_uid', '==', currentUserRef) */
            // .where('date', '>=', date30DaysAgo)
            .onSnapshot(
                querySnapshot => {
                    let adminInvoices = [];
                    querySnapshot.forEach(doc => {
						let invoice = doc.data();
						invoice.id = doc.id;
                        adminInvoices.push(invoice);
                    });
                    setAdminInvoices(adminInvoices);
                    setAdminInvoicesLoading(false);
					// console.info(`%cuseDbAdminInvoices: ${querySnapshot.docChanges().length} document(s) read`, 'color: blue');
                },
                err => {
                    setAdminInvoicesError(err);
                    // console.error(err);
                }
            );
        return () => {
            unsubscribe();
            // console.info('%cuseDbAdminInvoices: unsubscribed', 'color: blue');
        };
    }, []);

    return { AdminInvoicesLoading, AdminInvoicesError, AdminInvoices };
};

export default useDbAdminInvoices;
