
import React, { useState, useContext } from 'react';
//Vendor
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { db } from 'utils/firebase';

// Custom
import { InvoiceListItem as InvoiceList } from '../../components/styledComponents/invoices/invoiceTables';
import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';


// Component
function InvoiceListItem(props) {
  const customSnackbar = useContext(CustomSnackbarContext);
  const { student, StudentsLoading } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  /* const dispatch = useDispatch(); */
  const open = Boolean(anchorEl);

  let currencySign = "UGX"


  const handleOptionOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleDeleteInvoice = (e) => {
    e.stopPropagation();
    e.preventDefault();
  
      db.collection('students')
          .doc(student.id)
          .delete()
          .catch(error => {
              customSnackbar.error('An error has happened. Please try again.');
              console.error(error);
          });

      customSnackbar.success('Student Entry deleted.');
      
    setAnchorEl(null);
  };

/*   const togglePaymentStatus = (e) => {
    e.stopPropagation();
    e.preventDefault();

    db.collection('students')
    .doc(invoice.id)
    .update({
        paidStatus: true
    })
    .catch(error => {
        customSnackbar.error('An error has happened. Please try again.');
        console.error(error);
    });

    customSnackbar.success('Status changed.');
    setAnchorEl(null);

  }; */

  return (
      <InvoiceList>
        <p className="name">{`${student.surName} ${student.givenName}`}</p>
        <p className="class">{`${student.Class}${student.Stream}`}</p>
        <p className="amount">
            {currencySign +
              student.SchoolFees.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
        </p>
        {/* <p className="status">
          {invoice.paidStatus ? (
            <span style={{ color: '#219735' }}>Fulfilled</span>
          ) : (
            <span style={{ color: '#FD5665' }}>Pending</span>
          )}
        </p> */}
        <p className="date">
          {moment(student.created_at.toDate()).format('DD-MM-YYYY')}
        </p>
        <p className="option">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleOptionOpen}
            tabIndex={1}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            keepMounted
            open={open}
            onClose={handleOptionClose}
          >
            <MenuItem onClick={handleDeleteInvoice}>
              <p>Delete Entry</p>
            </MenuItem>
           {/*  <MenuItem onClick={togglePaymentStatus}>
              {invoice.paidStatus ? (
                <p>Mark as Pending</p>
              ) : (
                <p>Mark as Paid</p>
              )}
            </MenuItem> */}
          </Menu>
        </p>
      </InvoiceList>
  );
}

export default InvoiceListItem;