import React, {useState, useEffect, useContext} from 'react'
import moment from 'moment';
import { PDFExport } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import './invoiceLayout.css'
import { Button } from 'components/styledComponents/shared/Button';
import { ButtonDiv } from 'components/styledComponents/incvoiceDetails/InvoiceDetailButtons';

import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';

import goodid from 'goodid.js'


import emailjs from 'emailjs-com';


import bytLogo from './bytlogo.png';

const InvoiceView = (props) => {

    const customSnackbar = useContext(CustomSnackbarContext);

    const {invoice, InvoicesLoading} = props
    // const currencySign = invoice.currency === 'USD' ? '$' :'UGX'

    
    let currencySign = "UGX"

    if (!InvoicesLoading) {
        switch (invoice.currency) {
            case 'USD':
                currencySign ="$"
                break;
            case 'UGX':
                currencySign ="USh"
                break;
            case 'RWF':
                currencySign ="FRw"
                break;
            default:
                currencySign ="UGX"
                break;
        }
    }
                                       
    const [layoutSelection, ] = useState({ text: "A4", value: "size-a4"});

    const pdfExportComponent = React.useRef(null);

    const contentArea = React.useRef(null)

    /* const [instance, updateInstance] = usePDF(); */

  
    const exportPDFWithComponent = () => {
      if (pdfExportComponent.current) {
        customSnackbar.success(`Downloading PDF`)
        pdfExportComponent.current.save();
      }
    };

    const [pdfelement, setpdfelement] = useState();

    useEffect(() => {
        let gridElement = contentArea.current;
        setpdfelement(gridElement)
      }, []);

    const exportPDFwithMethod = (element) => {
        drawDOM(element, {
          paperSize: "A4",
        })
          .then((group) => {
            return exportPDF(group);
          })
          .then((dataUri) => {

           /*  console.log(dataUri.split(";base64,")[1]); */
           const templateParams = {
            to_name: invoice.LastName,
            email: invoice.email,
            message:"You have an invoice from Bytelex Advocates. Please review attached invoice for details.",
            content: dataUri
            };

            /* process.env.REACT_APP_EMAILJS_USER_ID */

            emailjs.send('service_8xz1t3m', 'template_pcrcb0j', templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
              .then((result) => {
                  customSnackbar.success(`${result.text}, Email Sent`);
                  /* console.log(result.text); */
              }, (error) => {
                  customSnackbar.error(`${error.text}, An error has happened. Please try again.`);
                  /* console.log(error.text); */
              });

          });

    }

    //email client
    const handleSendEmail = () => {
        customSnackbar.success(`Sending email to ${invoice.email}`);
        /* console.log("clicked") */
        exportPDFwithMethod(pdfelement);
        /* sendEmail(); */
    }

/*   const sendEmail =() => {
      
        emailjs.sendForm('service_8xz1t3m', 'template_ck7u8ka', 
        {to_name:"alex",
        message: "testing"
    }, process.env.REACT_APP_EMAILJS_USER_ID)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      }  */
  
    return (
        <div>
            <div className="page-container hidden-on-narrow" sytle={{margin:"0 auto !important",backgroundColor:"yellow !important" }}>
				<PDFExport 
                ref={pdfExportComponent}
                author="Bytelex Advocates"
                creator="NextLegal LLC"
                fileName={`${invoice.Attn}-${invoice.invoiceNumber}`}
                title={`Invoice ${invoice.invoiceNumber} from Bytlex Advocates`}
                producer="Bytelex Advocates"
                >
					<div className={ `pdf-page ${ layoutSelection.value }`} ref={contentArea}>
						<div className="inner-page">
							<div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
								<div className="company-logo">
									<img className="bytlogo" src={bytLogo} alt="Bytelex Logo" />
								</div>
								<div  style={{fontSize:"10px"}}>
                               {/*  <p className="byt_address">
                                Plot 52B, Ntinda II Road,<br />
                                P.O. Box 22106<br />
                                Tel No: 0414 671 057<br />
                                Kampala.
								</p> */}
                                {console.log(invoice.InvoiceOrigin)}
                                { invoice.invoiceOrigin === "Uganda" &&
                                    <p className="byt_address">
                                    Plot 52B, Ntinda II Road,<br />
                                    P.O. Box 22106<br />
                                    Tel No: 0414 671 057<br />
                                    Kampala.
                                    </p>
                                }
                                  { invoice.invoiceOrigin === "Rwanda" &&
                                    <p className="byt_address">
                                    8th floor tower A<br />
                                    M-Peace plaza<br />
                                    KN 4 Ave<br />
                                    Kigali, Rwanda.
                                    </p>
                                }
                                  { invoice.invoiceOrigin === "Kenya" &&
                                    <p className="byt_address">
                                    Plot 52B, Ntinda II Road,<br />
                                    P.O. Box 22106<br />
                                    Tel No: 0414 671 057<br />
                                    Kampala.
                                    </p>
                                }
                                </div>
							</div>
                            <div style={{textAlign:"center", fontWeight:"700", marginTop:"10px", marginBottom:"10px"}}> 
                            {invoice.invoiceType !== "regular" && <span>PRO FORMA</span>}   INVOICE
                            </div>
                            <div className="invoice-details"> 
                                <div className="client-box">
                                <p className="client-box-text">
                                Attn: {invoice.Attn}<br />
                                {invoice.CompanyName && `Company: ${invoice.CompanyName}`}
                                
                                <br />Address: {invoice.Address}<br />
								</p>
                                </div>
                                <div className="meta-box"> 
                                    <div className="invoice-financial_meta">
                                    <table> 
                                    <tr>
                                        <td style = {{border: 'solid thin', marginRight: '0px', paddingRight:'0px', width:'35%'}}>Invoice Date</td>
                                        <td style = {{borderRight: 'solid thin' , borderLeft:"none", borderBottom:"solid thin" ,borderTop:"solid thin", }}>
                                        {moment(invoice.created_at.toDate()).format('DD-MM-YYYY')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style = {{borderLeft: 'solid thin', borderRight:'solid thin', marginRight: '0px', paddingRight:'0px', width:'35%'}}>Invoice No.</td>
                                        <td style = {{borderRight: 'solid thin' , borderLeft:"none"}}>{invoice.invoiceNumber}</td>
                                    </tr>
                                    <tr>
                                        <td className="account" style = {{borderLeft: 'solid thin', borderRight:'solid thin', borderBottom:'solid thin', borderTop:"solid thin", marginRight: '0px', paddingRight:'0px', width:'35%'}}>Account:</td>
                                        <td style = {{borderRight: 'solid thin' , borderLeft:"none", borderBottom:"solid thin" ,borderTop:"solid thin"}}>
                                       {/* Ugandan Account */}
                                       {invoice.invoiceOrigin === "Uganda" && invoice.currency === "UGX" && <div> Guaranty Trust Bank (U) Ltd<br/>
                                        A/c No.<span style = {{fontWeight: '700'}}> 219137928151180</span><br />
                                        A/c Name:<span style = {{fontWeight: '700'}}> Bytelex Advocates </span><br />
                                        </div>
                                        }
                                        {invoice.invoiceOrigin === "Uganda" && invoice.currency === "USD" && <div> Guaranty Trust Bank (U) Ltd<br/>
                                        A/c No.<span style = {{fontWeight: '700'}}> 219137928251180</span><br />
                                        A/c Name:<span style = {{fontWeight: '700'}}> Bytelex Advocates </span><br />
                                        </div>
                                        }
                                        {/* equity bank dollar account */}
                                        {invoice.currency === "USD" && invoice.invoiceOrigin === "Rwanda" && <div>
                                        Equity Bank<br/>
                                        A/c No.<span style = {{fontWeight: '700'}}> 4002200574484</span><br />
                                        A/c Name:<span style = {{fontWeight: '700'}}> Bytelex Advocates </span><br />
                                        SWIFT CODE:<span style = {{fontWeight: '700'}}> EQBLRWRW </span><br />
                                        </div>
                                        }
                                        {/* equity bank rwanda shillings */}
                                        {invoice.currency === "RWF" && invoice.invoiceOrigin === "Rwanda" && <div>
                                        Equity Bank<br/>
                                        A/c No.<span style = {{fontWeight: '700'}}> 4001200727827</span><br />
                                        A/c Name:<span style = {{fontWeight: '700'}}> Bytelex Advocates </span><br />
                                        </div>
                                        }
                                        </td>
                                    </tr>
                                    
                                    </table>
                                    </div>
                                </div>
                            </div>
                            <div className="particulars">
                                <table border="1" style = {{border: 'none'}}> 
                                    <tr>
                                        <td className="patic-head" style = {{border: 'solid thin', width:'75%'}}>Particulars</td>
                                        <td className="amount-head" style = {{borderRight: 'solid thin' , borderLeft:"none", borderTop:"solid thin", borderBottom:"solid thin"}}>
                                            Amount<br />
                                            {invoice.currency}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="prof-fees" style = {{borderRight: 'solid thin', borderLeft: 'solid thin', borderBottom:"solid thin",width:'75%'}}>Professional fees</td>
                                        <td style = {{borderRight: 'solid thin', borderBottom:"solid thin",}}></td>
                                    </tr>
                                    <tr>
                                        <td style = {{borderRight: 'solid thin', borderLeft: 'solid thin',width:'75%'}}>To instructions to:
                                            <div style={{marginLeft:"15px,", marginTop:0}}>
                                                <ol className="invOl" type="a" style={{marginTop:0}}>
                                                    {invoice.instructions.map((invoice) => (
                                                        <li className="invLi" key={goodid()}>{invoice.particulars}</li>
                        
                                                    ))}
                                                </ol>
                                            </div>
                                        </td>
                                        <td style={{textAlign:'end', verticalAlign:'top', borderRight: 'solid thin' , borderLeft:"none"}} >
                                        {currencySign +
                                    parseFloat(invoice.ProfessionalFees).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="prof-fees" style = {{border: 'solid thin',width:'75%' }}>Disbursements</td>
                                        <td style = {{borderRight: 'solid thin', borderBottom:"solid thin", borderTop:'solid thin'}}></td>
                                    </tr>
                                    <tr>
                                        <td  style = {{borderRight: 'solid thin', borderLeft: 'solid thin', borderBottom:"solid thin", width:'75%'}}>
                                            <div className="disbur">
                                                To all necessary disbursements inclusive relevant authority fees, charges, transport costs and bank charges
                                            </div>
                                        </td>
                                        <td style = {{/* border: 'solid thin' */borderRight: 'solid thin', borderBottom:"solid thin",}}></td>
                                    </tr>
                                   {invoice.disbursments.map((dis)=>{
                                       return   <tr key={goodid()} sytle={{padding: 0}}>
                                                <td style = {{borderBottom: 'none', borderLeft:'solid thin', borderRight:'solid thin'}}>
                                                    <div className="disbur">
                                                    {dis.DisbursementDescription}
                                                    </div>
                                                </td>
                                                <td style={{textAlign:'end', borderRight:'solid thin'}}>
                                                                {currencySign + parseFloat(dis.DisbursementAmount).toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </td>
                                                </tr>
                                   })}
                                    <tr>
                                        <td style = {{borderBottom: 'none', textAlign:'center', borderLeft:'solid thin', borderRight:'solid thin'}}>
                                            <div className="disbur">
                                                SUB TOTAL
                                            </div>
                                        </td>
                                        <td style={{textAlign:'end', borderRight:'solid thin'}}>
                                            {currencySign +
                                                    invoice.disbursementsTotal.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                            
                                            </td>
                                    </tr>
                                    <tr>
                                        <td style = {{border: 'solid thin', textAlign:'center', fontWeight:"700"}}>GRAND TOTAL</td>
                                        <td style = {{borderRight: 'solid thin', borderTop:'solid thin', borderBottom:"solid thin", borderLeft:"none", fontWeight: '700', textAlign:'end'}}>   
                                        {currencySign +
                                    invoice.totalAmount.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                        </td>
                                    </tr>  
                                </table>

                                <div className="salutations" style ={{ marginTop: "30px", fontSize:"11px"}}>
                                    <p>Yours faithfully,</p>
                                    <p style ={{fontWeight: 'bold'}}>Bytelex Advocates</p>
                                </div>

                            </div>
						</div>
					</div>
				</PDFExport>
                <ButtonDiv>
      {/*   <Button
       
          color="#fda734"
        >
          <i
            className={
              invoice.paidStatus
                ? 'tio-help_outlined'
                : 'tio-checkmark_circle_outlined'
            }
          ></i>{' '}
          {invoice.paidStatus ? 'Mark Pending' : 'Mark Paid'}
         
        </Button> */}

        <Button
         /*  onClick={handleEmailInvoice} */
         onClick={handleSendEmail}
         disabled={invoice.paidStatus} 
        >
          <i className="tio-send"></i> Send Email
        </Button>


        <Button
          secondary
          onClick={exportPDFWithComponent}
        >
          <i className="tio-download_to k-button"></i>{' '}
            Download
        </Button>

       {/*  <Button as="a" target="_blank" secondary onClick={handleSubmit}>
          <i className="tio-print"></i> Print
        </Button> */}
      {/*   <Button color="#FD5665">
          <i className="tio-delete"></i> Delete
        </Button> */}
      </ButtonDiv>
			</div>

          {/*   <div>
                <div className="example-config">
                    <button className="k-button" onClick={exportPDFWithComponent}>
                    Export to PDF with component
                    </button>
                </div>
            </div> */}

        </div>
    )
}

export default InvoiceView
