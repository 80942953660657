import { useState, useEffect } from 'react';

import { db } from 'utils/firebase';

const useDbAdminProjects = () => {
    const [AdminprojectsLoading, setAdminProjectsLoading] = useState(true);
    const [AdminprojectsError, setAdminProjectsError] = useState(false);
    const [Adminprojects, setAdminProjects] = useState(null);

    useEffect(() => {
        /* const currentUserRef = db.collection('users').doc(firebase.auth().currentUser.uid); */
		
        const unsubscribe = db
            .collection('projects')
            /* .where('owner_uid', '==', currentUserRef) */
            .onSnapshot(
                querySnapshot => {
                    let Adminprojects = [];
                    querySnapshot.forEach(doc => {
						let project = doc.data();
						project.id = doc.id;
                        Adminprojects.push(project);
                    });
                    setAdminProjects(Adminprojects);
                    setAdminProjectsLoading(false);
					// console.info(`%cuseDbAdminProjects: ${querySnapshot.docChanges().length} document(s) read`, 'color: blue');
                },
                err => {
                    setAdminProjectsError(err);
                    // console.error(err);
                }
            );
        return () => {
            unsubscribe();
            // console.info('%cuseDbAdminProjects: unsubscribed', 'color: blue');
        };
    }, []);

    return { AdminprojectsLoading, AdminprojectsError, Adminprojects };
};

export default useDbAdminProjects;
