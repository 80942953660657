import React, {useContext} from 'react';
//Vendor
import { Link } from 'react-router-dom';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

// Custom
import { Widget } from 'components/styledComponents/dashboard/Widget';
import { DbAdminInvoicesContext } from 'utils/firebase';



// Component
function TotalInvoices() {
 

const { AdminInvoices, AdminInvoicesLoading  } = useContext(DbAdminInvoicesContext);

const paraStyle = {
  display:"block",
  /* marginBlockStart:"1em",
  marginBlockEnd:"1em",
  marginInlineStart:"0px",
  marginInlineEnd:"0px" */
}

const headyStyle = {
 /*  display:"block",  */
  fontSize:"1.5em", 
 /*  marginBlockStart:"0.83em", 
  marginBlockEnd:"0.83em",
  marginInlineStart:"0px", 
  marginInlineEnd:"0px",  */
  fontWeight:"bold"
}


  return (
    
    <Widget as={Link} to={'/admin/invoices'} color="#00C1D4">
      <div style={{marginRight:"15px"}}>
        <MonetizationOnIcon />
      </div>
      <div className="widget-text">
        <h2 style={headyStyle}>Invoices</h2>
         {!AdminInvoicesLoading? <p style={paraStyle}>Total Invoices: {AdminInvoices.length}</p> :  <p style={paraStyle}>Total Invoices: 0</p> }
      </div>
    </Widget>
    
    
  );
}

export default TotalInvoices;