import React, { useState, useMemo } from 'react';

import _ from 'lodash';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import FullPageLoader from 'components/loaders/FullPageLoader';
import NavBar from './NavBar';
import LeftDrawer from './LeftDrawer';
import AuthRoutes from 'navigation/AuthRoutes';

import {
    useDbUser,
    DbUserContext,
    useDbUsers,
    DbUsersContext,
    useDbProjects,
    useDbAdminProjects,
    DbProjectsContext,
    DbAdminProjectsContext,
    useDbTimeEntries,
    DbTimeEntriesContext,
    useDbInvoices,
    useDbAdminInvoices,
    DbInvoicesContext,
    DbAdminInvoicesContext,
    DbStudentsContext,
    useDbStudents

} from 'utils/firebase';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    routerBox: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    }
}));

function MainPage(props) {
    const classes = useStyles();

    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const { userLoading, userError, user, userInterface } = useDbUser();
    const { usersLoading, usersError, users } = useDbUsers();
    const { projectsLoading, projectsError, projects } = useDbProjects();
    const { timeEntriesLoading, timeEntriesError, timeEntries } = useDbTimeEntries();
    const { InvoicesLoading, InvoicesError, Invoices } = useDbInvoices();
    const {StudentsLoading, StudentsError, Students} = useDbStudents();
    const { AdminInvoicesLoading, AdminInvoicesError, AdminInvoices } = useDbAdminInvoices();
    const { AdminprojectsLoading, AdminprojectsError, Adminprojects } = useDbAdminProjects();

    const handleDrawerToggle = () => {
        setMobileDrawerOpen(!mobileDrawerOpen);
    };

    console.log('students', Students)
    // console.log('userInterface', userInterface) //DEBUG
    // console.log('user', user); // DEBUG
    // console.log('users', users); // DEBUG
    // console.log('projects', projects); // DEBUG
    // console.log('timeEntries', timeEntries); // DEBUG
    // console.log('Invoices', Invoices); // DEBUG
    // console.log('AdminInvoices', AdminInvoices); // DEBUG
    // console.log('AdminProjects', Adminprojects); // DEBUG

    const activeProjects = useMemo(() => _.filter(projects, p => !p.archived), [projects]);
    const activeProjectsExist = !_.isEmpty(activeProjects);

    if (userLoading || projectsLoading || timeEntriesLoading || InvoicesLoading) {
        return <FullPageLoader />;
    }

    return (
        <DbUsersContext.Provider value={{ usersLoading, usersError, users }}>
        <DbUserContext.Provider value={{ userLoading, userError, user, userInterface }}>
            <DbAdminProjectsContext.Provider value={{ AdminprojectsLoading, AdminprojectsError, Adminprojects }}>
            <DbAdminInvoicesContext.Provider value={{ AdminInvoicesLoading, AdminInvoicesError, AdminInvoices }}>
            <DbProjectsContext.Provider value={{ projectsLoading, projectsError, projects }}>
                <DbInvoicesContext.Provider value={{ InvoicesLoading, InvoicesError, Invoices }}>
                <DbStudentsContext.Provider value={{ StudentsLoading, StudentsError, Students }}>
                <DbTimeEntriesContext.Provider value={{ timeEntriesLoading, timeEntriesError, timeEntries }}>
                    <Box height="100%">
                        <LeftDrawer
                            mobileOpen={mobileDrawerOpen}
                            drawerWidth={drawerWidth}
                            onDrawerToggle={handleDrawerToggle}
                        />
                        <NavBar drawerWidth={drawerWidth} onDrawerToggle={handleDrawerToggle} />
                        <Box className={classes.routerBox}>
                            <AuthRoutes activeProjectsExist={activeProjectsExist} />
                        </Box>
                    </Box>
                </DbTimeEntriesContext.Provider>
                </DbStudentsContext.Provider>
                </DbInvoicesContext.Provider>
            </DbProjectsContext.Provider>
            </DbAdminInvoicesContext.Provider>
            </DbAdminProjectsContext.Provider>
        </DbUserContext.Provider>
        </DbUsersContext.Provider>
    );
}

export default MainPage;
