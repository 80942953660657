import React, { useContext } from 'react';
// Vendor
/* import { useSelector, useDispatch } from 'react-redux'; */
import { useParams } from 'react-router';
import AppLoader from 'components/loaders/AppLoader';
import InvoiceView from './InvoiceView';


/* import { db } from 'utils/firebase'; */
import {
  /* useDbInvoices, */
  DbAdminInvoicesContext
} from 'utils/firebase';;



// Component
function AdminInvoiceDetails() {
  const { id } = useParams();

  const { AdminInvoices, AdminInvoicesLoading  } = useContext(DbAdminInvoicesContext);

  const invoiceArray =  AdminInvoices.filter(function(invoice) {
    return invoice.id === id;
});

const invoice = invoiceArray[0]




/* console.log("pdf invoice", invoice); */

  /* const loadingState = useSelector((state) => state.loadingState.emailSendBtn); */

  if (AdminInvoicesLoading) return <AppLoader />;

  /* const handleDeleteInvoice = () => {
    dispatch(confirmDeleteAction(deleteInovice(id)));
  };

  const handleEmailInvoice = () => {
    dispatch(confirmEmailReminder(sendInvoiceMail(id)));
  };

  const handlePaymentStatus = () => {
    dispatch(
      confirmPaymentChangeAction(updatePaymentStatus(id, !invoice.paidStatus))
    );
  }; */



  return (
    <div>
      {/* <Header title={'Invoice Details'} /> */}
     {/*  <InvoicePD invoice={invoice} /> */}
     <InvoiceView invoice={invoice}></InvoiceView>
    </div>
  );
}

export default AdminInvoiceDetails;
