import React, {useContext} from 'react';
//Vendor
import { Link } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';

// Custom
import { Widget } from 'components/styledComponents/dashboard/Widget';
import { DbUsersContext } from 'utils/firebase';



// Component
function TotalUsers() {
 

const { usersLoading, users } = useContext(DbUsersContext);

const paraStyle = {
  display:"block",
  /* marginBlockStart:"1em",
  marginBlockEnd:"1em",
  marginInlineStart:"0px",
  marginInlineEnd:"0px" */
}

const headyStyle = {
 /*  display:"block",  */
  fontSize:"1.5em", 
 /*  marginBlockStart:"0.83em", 
  marginBlockEnd:"0.83em",
  marginInlineStart:"0px", 
  marginInlineEnd:"0px",  */
  fontWeight:"bold"
}


  return (
    
    <Widget as={Link} to={'/admin/users'} color="#00C1D4">
      <div style={{marginRight:"15px"}}>
        <PeopleIcon></PeopleIcon>
      </div>
      <div className="widget-text">
        <h2 style={headyStyle}>Attorneys</h2>
         {!usersLoading? <p style={paraStyle}>Attorneys: {users.length}</p> :  <p style={paraStyle}>Attorneys: 0</p> }
      </div>
    </Widget>
    
    
  );
}

export default TotalUsers;