import React, { useContext } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

import Projects from 'containers/Projects';
import Time from 'containers/Time';
import Reports from 'containers/Reports';
import Account from 'containers/Account';
import NotFound from 'components/NotFound';
import Invoices from 'containers/Invoices';
import AdminInvoices from 'containers/Admin/adminInvoices';
import AdminUsers from 'containers/Admin/adminUsers';
import Admin from 'containers/Admin';
import InvoiceDetails from 'components/view/InvoiceDetails'
import AdminInvoiceDetails from 'components/view/AdminInvoiceDetails'
import { generateToTime } from './locations';
import AdminReports from 'containers/Reports/adminReports';

import { DbUserContext } from 'utils/firebase';

function AuthRoutes(props) {
    const { activeProjectsExist, location } = props;
    const { userInterface } = useContext(DbUserContext);


    return (
        <Switch>
           
            <Route exact path="/" component={Projects} />
            <Route exact path="/time" component={Time} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/students" component={Invoices} />
            {userInterface.admin &&<Route exact path="/admin/invoices" component={AdminInvoices} />}
            {userInterface.admin &&<Route exact path="/admin/users" component={AdminUsers} />}
            {userInterface.admin &&<Route exact path="/admin/users/:id" component={AdminReports} />}
            {userInterface.admin &&<Route exact path="/admin/invoice/:id" component={AdminInvoiceDetails} />}
            {userInterface.admin &&<Route exact path="/admin" component={Admin} />}
            <Route exact path="/invoice/:id" component={InvoiceDetails} />
            <Redirect from="/login" to={activeProjectsExist ? generateToTime(location) : '/'} />
            <Redirect from="/signup" to={activeProjectsExist ? generateToTime(location) : '/'} />
            <Route component={NotFound} />
        </Switch>
    );
}

export default withRouter(AuthRoutes);
