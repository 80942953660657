import React, {useContext} from 'react';
//Vendor
import { Doughnut } from 'react-chartjs-2';
import { GraphWidget } from 'components/styledComponents/dashboard/Widget';
// Custom
import PieLoader from '../../../components/loaders/PieLoader';
import { DbAdminInvoicesContext } from 'utils/firebase';

// Component
function PieGraph() {
    const { AdminInvoices, AdminInvoicesLoading  } = useContext(DbAdminInvoicesContext);

  if (AdminInvoicesLoading) {
    return <PieLoader />;
  }

  if (!AdminInvoicesLoading) {
    const paidInvoice = AdminInvoices.filter((invoice) => invoice.paidStatus).length;
    const pendingInvoice = AdminInvoices.filter((invoice) => !invoice.paidStatus)
      .length;

    const chartData = {
      labels: ['Fulfilled Invoice', 'Pending Invoices'],
      datasets: [
        {
          label: 'Rahu',
          data: [paidInvoice, pendingInvoice],
          backgroundColor: ['#24B47E3a', '#F037383a'],
          borderColor: ['#24B47E', '#F03738'],
          borderWidth: 1
        }
      ]
    };

    return (
      <GraphWidget>
        <h3>Summary</h3>
        <Doughnut data={chartData} width={500} height={500} />
      </GraphWidget>
    );
  }
  return null;
}

export default PieGraph;