import React, { useContext } from 'react';

//Vendor
import { FlatButton } from 'components/styledComponents/shared/Button';
import { Link } from 'react-router-dom';

// Custom

import {
  InvoiceTable,
  InvoiceListHead,
  TableHeading
} from '../../components/styledComponents/invoices/invoiceTables';
/* '../../styledComponents/invoices/invoiceTables'; */
import InvoiceListItem from './AdminInvoiceListItem';
import InvoiceListLoader from '../../components/loaders/InvoiceListLoader';

/* import NothingHere from '../../loaders/welcome/NothingHere'; */

import {
  /* useDbInvoices, */
  DbAdminInvoicesContext
} from 'utils/firebase';



// Component
function RecentInvoicesTable() {
  const { AdminInvoices, AdminInvoicesLoading  } = useContext(DbAdminInvoicesContext);

  


  let tableListItems;

/*   if ( InvoicesLoading ) {
    return <FullPageLoader />;
} */
if (AdminInvoicesLoading) {
  return <InvoiceListLoader />
} else {
  const sortedInvoices = AdminInvoices.sort((a, b) => b.created_at - a.created_at)
  tableListItems = sortedInvoices.slice(0, 4).map((invoice) => (
    <InvoiceListItem invoice={invoice} key={invoice.id} />
  ));
}

/* InvoicesLoading? <InvoiceListLoader /> :  */

/*   if (isLoaded(invoices) && isEmpty(invoices))
    return (
      <>
        <Header title={'Invoices'} />
        <NothingHere />
      </>
    );

  if (isLoaded(invoices)) {
    tableListItems = invoices.map((invoice) => (
      <InvoiceListItem invoice={invoice} key={invoice.id} />
    ));
  }
  if (!isLoaded(invoices)) {
    tableListItems = Array.from({ length: 10 }).map((invoice) => (
      <InvoiceListLoader />
    ));
  } */

  const invHStyle = {
    fontWeight:"bold"
  }

  return (
    <div>
      {/* <Header title={'Invoices'} /> */}
      <div style={{ padding: '1rem' }}>
        <InvoiceTable style={{ margin: '0 auto',  }}>
          <TableHeading>
            <h2 style={invHStyle}>Recent Invoices</h2>
            <div className="heading-cta">
          <FlatButton as={Link} to="/admin/invoices">
            View All
          </FlatButton>
        </div>
          </TableHeading>
          <InvoiceListHead>
            <p className="listHead number">No.</p>
            <p className="listHead date">Date</p>
            <p className="listHead name">Author</p>
            <p className="listHead client">Client</p>
            <p className="listHead amount">Amount</p>
            <p className="listHead status">Status</p>
            <p className="listHead option"></p>
          </InvoiceListHead>
          {tableListItems}
        </InvoiceTable>
      </div>
    </div>
  );
}

export default RecentInvoicesTable;