
import React, { useState, useContext } from 'react';
//Vendor
/* import moment from 'moment'; */
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';

import { /* db, */ functions } from 'utils/firebase';


// Custom
import { UserListItem as UserList } from '../../components/styledComponents/invoices/invoiceTables';
import { CustomSnackbarContext } from 'components/extensions/CustomSnackbar';



// Component
function UserListItem(props) {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  
  const open = Boolean(anchorEl);
  const customSnackbar = useContext(CustomSnackbarContext);


  const handleOptionOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

/*   const handleDeleteInvoice = (e) => {
    e.stopPropagation();
    e.preventDefault();
  
      db.collection('invoices')
          .doc(invoice.id)
          .delete()
          .catch(error => {
              customSnackbar.error('An error has happened. Please try again.');
              console.error(error);
          });

      customSnackbar.success('Invoice deleted.');
      
    setAnchorEl(null);
  }; */

    const handleMakeAdmin = (e) => {
    e.stopPropagation();
    e.preventDefault();


    const addAdminRole = functions.httpsCallable('addAdminRole');
      addAdminRole({ email: user.email }).then(result => {
      customSnackbar.success(`${result.data.message}`);
  });

  setAnchorEl(null);
  
  }; 

/*   const togglePaymentStatus = (e) => {
    e.stopPropagation();
    e.preventDefault();

    db.collection('invoices')
    .doc(invoice.id)
    .update({
        paidStatus: true
    })
    .catch(error => {
        customSnackbar.error('An error has happened. Please try again.');
        console.error(error);
    });

    customSnackbar.success('Status changed.');
    setAnchorEl(null);

  }; */

  
  return (
    <Link to={`/admin/users/${user.id}`} >
      <UserList>
        <p className="name user">{`${user.name}`}</p>
        <p className="option">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleOptionOpen}
            tabIndex={1}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            keepMounted
            open={open}
            onClose={handleOptionClose}
          >
            <MenuItem /* onClick={handleDeleteuser} */>
              <p>Delete User</p>
            </MenuItem>
            <MenuItem onClick={handleMakeAdmin}>
              <p>Make Admin</p>
            </MenuItem>
          </Menu>
        </p>
      </UserList>
    </Link>
  );
}

export default UserListItem;